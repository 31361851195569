<template>
  <div>
    <div class="mb-4">
      <vx-card>
        <div>
          <div class="grid-cols-2 grid justify-items-stretch">
            <div>
              <p class="p-4 text-2xl mb-4">
                {{ `Galería actual de ${project.name}` }}
              </p>
            </div>
            <div class="justify-self-end">
              <button
                @click="openImageForm = !openImageForm"
                class="bg-blue-flattlo p-4 text-white rounded-lg shadow px-8 text-base hover:bg-success-flattlo flex"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
                <p class="ml-2 hidden md:block">Imagen</p>
              </button>
            </div>
          </div>
          <div class="justify-items-end p-4 -mt-6">
            <div class="grid grid-cols-1 gap-6 mx-20" v-if="openImageForm">
              <div>
                <span>Tipo de imágen</span>
                <v-select
                  v-model="imageType"
                  :options="projectImageGalleryTypes"
                ></v-select>

                <vs-input
                  size="large"
                  icon-pack="fas"
                  class="w-full mt-4"
                  icon="fa-image"
                  type="text"
                  label="Título de imagen"
                  color="primary"
                  v-model="title"
                  v-validate="'required'"
                />
                <div
                  class="image-box mt-4 flex justify-center"
                  v-if="imageHeaderReadyToUplad"
                >
                  <img
                    :src="perfilHeaderTemp"
                    class="responsive rounded-lg h-auto w-1/2"
                  />
                </div>
                <div class="grid justify-center">
                  <button
                    v-if="imageHeaderReadyToUplad"
                    class="w-full mt-6 bg-success-flattlo px-8 py-2 rounded-sm shadow hover:bg-green-400 hover:shadow-lg text-lg text-white"
                    @click="addImageToProject"
                  >
                    Guardar imagen
                  </button>
                  <button
                    class="mt-3 text-red-400 text-lg w-full justify-center flex"
                    @click="cancelarHeader"
                    v-if="imageHeaderReadyToUplad"
                    href="#"
                  >
                    Cancelar
                  </button>
                </div>
              </div>

              <div>
                <button
                  @click="isShowModal = true"
                  v-if="!imageHeaderReadyToUplad"
                  type="button"
                  class="relative block w-full border-2 border-blue-500 border-dashed rounded-lg p-12 text-center hover:border-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mx-auto h-12 w-12 text-blue-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                    />
                  </svg>
                  <span class="mt-2 block text-sm font-medium text-gray-900">
                    Subir imagen
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <vs-table
          ref="table"
          single
          v-model="selected"
          pagination
          :max-items="itemsPerPage"
          :data="projectImageGallery"
          noDataText="📸 No hay Imagenes creadas..."
        >
          <template slot="thead">
            <vs-th>Imagen</vs-th>
            <vs-th>Nombre</vs-th>
            <vs-th>Clasificación</vs-th>
            <vs-th>Acciones</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
                <vs-td :data="data[index].img_url">
                  <img
                    :src="data[index].img_url"
                    class="w-auto h-48 rounded-sm"
                  />
                </vs-td>
                <vs-td>
                  <p>{{ data[index].title }}</p>
                </vs-td>
                <vs-td :data="data[index].project_image_gallery">
                  <vs-chip>{{
                    data[index].projectImageGalleryByProjectImageGallery.name
                  }}</vs-chip>
                </vs-td>
                <vs-td>
                  <vs-row>
                    <vx-tooltip
                      color="danger"
                      text="Eliminar Imagen"
                      position="bottom"
                    >
                      <vs-button
                        color="danger"
                        type="flat"
                        icon-pack="feather"
                        icon="icon-trash"
                        class="p-1"
                        @click="deleteProjectImage(data[index].id)"
                      ></vs-button>
                    </vx-tooltip>
                  </vs-row>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
    <div>
      <vs-row>
        <vs-col vs-justify="center" vs-align="center" vs-w="12">
          <vx-card>
            <div>
              <p class="p-4 text-2xl mb-4">Vídeo de Proyecto</p>
            </div>
            <div class="w-full sm:w-full">
              <vs-input
                size="large"
                icon-pack="fas"
                class="w-full"
                icon="fa-image"
                type="text"
                label="URL de video"
                color="primary"
                v-validate="'required'"
                v-model="youTubeUrl"
              />
              <iframe
                allowfullscreen
                v-if="youTubeUrl"
                :src="checkMediaUrl(youTubeUrl, 'youTubeUrl')"
                class="w-full mt-3 embed-element"
              ></iframe>
              <vs-button
                :disabled="!isYouTubeUrl"
                @click="storeElement(idYouTubeUrl, youTubeUrl, 'VIDEO_URL')"
                class="w-full mt-3"
              >
                {{ youTubeUrl ? "Actualizar" : "Guardar" }}
              </vs-button>
            </div>
          </vx-card>
          <vx-card class="mt-4">
            <div>
              <p class="p-4 text-2xl mb-4">Tour virtual de proyecto</p>
            </div>
            <div class="w-full sm:w-full mt-3">
              <vs-input
                size="large"
                icon-pack="fas"
                class="w-full"
                icon="fa-image"
                type="text"
                label="URL recorrido virtual"
                color="primary"
                v-validate="'required'"
                v-model="virtualSlideUrl"
              />
              <iframe
                v-if="virtualSlideUrl"
                :src="checkMediaUrl(virtualSlideUrl, 'virtualSlideUrl')"
                class="w-full mt-3 embed-element"
              ></iframe>
              <vs-button
                :disabled="!isVirtualSlideUrl"
                @click="
                  storeElement(
                    idVirtualSlideUrl,
                    virtualSlideUrl,
                    'VIDEO_TOUR_URL'
                  )
                "
                class="w-full mt-3"
              >
                {{ virtualSlideUrl ? "Actualizar" : "Guardar" }}
              </vs-button>
            </div>
          </vx-card>
          <vx-card class="mt-4">
            <div>
              <p class="p-4 text-2xl mb-4">Presentación de proyecto</p>
            </div>
            <div class="w-full sm:w-full mt-3">
              <vs-textarea
                size="large"
                icon-pack="fas"
                class="w-full"
                icon="fa-image"
                type="text"
                label="Google Slide Embed"
                color="primary"
                v-validate="'required'"
                v-model="embedSlideCode"
              />
              <div
                v-if="embedSlideCode"
                v-html="checkEmbedUrl(embedSlideCode)"
                class="w-full mt-3 embed-element"
              ></div>
              <vs-button
                :disabled="!isEmbedSlideCode"
                @click="
                  storeElement(
                    idEmbedSlideCode,
                    embedSlideCode,
                    'EMBED_CODE_SLIDE'
                  )
                "
                class="w-full mt-3"
              >
                {{ embedSlideCode ? "Actualizar" : "Guardar" }}
              </vs-button>
            </div>
          </vx-card>
          <vx-card class="mt-4">
            <div>
              <p class="p-4 text-2xl mb-4">PDF del proyecto</p>
            </div>
            <div class="w-full sm:w-full">
              <vs-input
                size="large"
                icon-pack="fas"
                class="w-full"
                icon="fa-image"
                type="text"
                label="URL del PDF"
                color="primary"
                v-validate="'required'"
                v-model="issuuPdfUrl"
              />
              <iframe
                allowfullscreen
                v-if="issuuPdfUrl"
                :src="checkPdfUrl(issuuPdfUrl)"
                class="w-full mt-3 embed-element"
              ></iframe>
              <vs-button
                :disabled="!isPdfUrl"
                @click="
                  storeElement(idIssuuPdfUrl, issuuPdfUrl, 'ISSUU_PDF_URL')
                "
                class="w-full mt-3"
              >
                {{ issuuPdfUrl ? "Actualizar" : "Guardar" }}
              </vs-button>
            </div>
          </vx-card>
        </vs-col>

        <!-- popUp -->
        <vs-popup title="Recortar imagen" :active.sync="isShowModal">
          <input
            ref="input"
            type="file"
            id="image"
            name="image"
            accept="image/*"
            @change="setImage"
          />
          <VueCropper
            v-show="imgSrc"
            ref="cropper"
            :aspect-ratio="16 / 9"
            :src="imgSrc"
            preview=".preview"
          />
          <div v-show="imgSrc" class="actions mt-4">
            <vs-button class="mr-2" @click.prevent="zoom(0.2)">
              Acercar
            </vs-button>
            <vs-button class="ml-2" @click.prevent="zoom(-0.2)">
              Alejar
            </vs-button>
            <vs-button class="ml-2" @click.prevent="cropSuccess">
              Cortar imagen
            </vs-button>
          </div>
          <section v-show="perfilHeaderTemp" class="cropped-image py-4">
            <div class="cropped-image">
              <div
                class="image-profile image-profile-size"
                v-if="perfilHeaderTemp"
                :style="{
                  backgroundImage: `url('${perfilHeaderTemp}')`,
                }"
                alt="Cropped Image"
              />
              <div v-else class="crop-placeholder" />
            </div>
          </section>
          <div class="py-4">
            <vs-button
              type="border"
              @click="(isShowModal = false), cancelarHeader()"
              >Cancelar</vs-button
            >
            <vs-button
              v-show="perfilHeaderTemp"
              class="ml-2"
              color="success"
              @click="saveImage"
              >Utilizar imagen</vs-button
            >
          </div>
        </vs-popup>
      </vs-row>
    </div>
  </div>
</template>

<script>
import S3 from "aws-s3";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { api } from "../services";
import { resizeImage } from "../../../helpers/jimp";
import vSelect from "vue-select";
import VxCard from "../../../components/vx-card/VxCard.vue";
import slugify from "../../../helpers/slugify";

export default {
  data() {
    return {
      title: "",
      imageHeaderReadyToUplad: false,
      perfilHeader: "",
      perfilHeaderTemp: "",
      isShowModal: false,
      imgSrc: "",
      project: null,
      projectImageGallery: [],
      youTubeUrl: null,
      issuuPdfUrl: null,
      idIssuuPdfUrl: null,
      idYouTubeUrl: null,
      isYouTubeUrl: false,
      isPdfUrl: false,
      virtualSlideUrl: null,
      idVirtualSlideUrl: null,
      isVirtualSlideUrl: false,
      embedSlideCode: null,
      idEmbedSlideCode: null,
      isEmbedSlideCode: false,
      itemsPerPage: 3,
      selected: [],
      projectImageGalleryTypes: [],
      imageType: null,
      openImageForm: false,
    };
  },
  components: {
    VueCropper,
    vSelect,
    VxCard,
  },
  async mounted() {
    const projectId = this.$store.state.project.currentProjectId;
    this.project = await api.get(projectId);
    this.projectImageGallery = await api.getProjectImages(
      projectId,
      "FINISHES"
    );
    const projectImageGallery = await api.getProjectImageGallery();
    this.projectImageGalleryTypes = projectImageGallery.map((obj) => {
      if (obj.id !== "COVER") {
        return { label: obj.name, value: obj.id };
      }
    });
    this.setAttributes();

    this.$store.state.project.breadcrumbs = [
      { pageName: "Edición de proyectos", route: "/proyectos" },
      {
        pageName: "Multimedia",
        route: `/galerias-de-proyecto/${this.$route.params.projectId}`,
      },
    ];
  },
  methods: {
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    cropSuccess() {
      this.perfilHeaderTemp = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    async saveImage() {
      this.isShowModal = false;
      this.imageHeaderReadyToUplad = true;
      this.perfilHeader = await this.dataURIToFile(this.perfilHeaderTemp);
    },
    dataURIToFile: function (data) {
      let type = data.split(";", [1])[0];
      type = type.split(":", [2])[1];
      const date = new Date();
      return fetch(data)
        .then((response) => response.arrayBuffer())
        .then((response) => new File([response], date.getDate(), { type }));
    },
    cancelarHeader() {
      this.imageHeaderReadyToUplad = false;
      this.perfilHeader = "";
      (this.perfilHeaderTemp = ""), (this.imgSrc = "");
      this.title = "";
      this.imageType = null;
      document.getElementById("image").value = "";
    },
    async addImageToProject() {
      try {
        const imageWidth = 2000;
        const imageHeight = 1125;
        const imageQuality = 60;
        const imageResized = await resizeImage(
          this.perfilHeaderTemp,
          imageWidth,
          imageHeight,
          imageQuality
        );
        const currentDate = Date.now();
        const filename = `${slugify(this.title)}_${currentDate}`;

        const uploadImage = await this.S3Client.uploadFile(
          imageResized,
          filename
        );
        await api.addProjectImage({
          title: this.title,
          img_url: uploadImage.location,
          project_image_gallery: this.imageType
            ? this.imageType.value
            : "FINISHES",
          project_id: this.project.id,
        });
        this.$vs.notify({
          time: 6000,
          title: `Imagen guardada.`,
          text: `La imagen fue guardada correctamente.`,
          color: "success",
        });
        this.title = "";
        this.cancelarHeader();
        this.openImageForm = false;
        this.projectImageGallery = await api.getProjectImages(
          this.project.id,
          "FINISHES"
        );
      } catch (e) {
        this.$vs.notify({
          title: `Error`,
          text: `No ha sido posible guardar la imagen.`,
          color: "danger",
        });
      }
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    async storeElement(settingId, settingValue, settingEnum) {
      try {
        if (!settingId) {
          await api.addProjectSetting(
            this.project.id,
            settingValue,
            settingEnum
          );
          this.$vs.notify({
            time: 6000,
            title: `Nuevo elemento de presentación.`,
            text: `Dato guardado correctamente.`,
            color: "success",
          });
        } else {
          await api.updProjectSettingById(settingId, {
            value: settingValue,
            project_setting: settingEnum,
          });
          this.$vs.notify({
            time: 6000,
            title: `Actualización elemento de presentación.`,
            text: `Dato actualizado correctamente.`,
            color: "success",
          });
        }

        this.project = await api.get(this.project.id);
        this.setAttributes();
      } catch (e) {
        this.$vs.notify({
          title: `Error`,
          text: `No ha sido posible guardar elemento de presentación en este momento.`,
          color: "danger",
        });
      }
    },
    setAttributes() {
      const projectSetting = this.project.project_setting_values;
      if (projectSetting.length) {
        const videoUrlSetting = projectSetting.find((item) => {
          return item.project_setting === "VIDEO_URL";
        });
        if (videoUrlSetting) {
          this.youTubeUrl = videoUrlSetting.value;
          this.idYouTubeUrl = videoUrlSetting.id;
        }
        const videoTourSetting = projectSetting.find((item) => {
          return item.project_setting === "VIDEO_TOUR_URL";
        });
        if (videoTourSetting) {
          this.virtualSlideUrl = videoTourSetting.value;
          this.idVirtualSlideUrl = videoTourSetting.id;
        }
        const embedCodeSetting = projectSetting.find((item) => {
          return item.project_setting === "EMBED_CODE_SLIDE";
        });
        if (embedCodeSetting) {
          this.embedSlideCode = embedCodeSetting.value;
          this.idEmbedSlideCode = embedCodeSetting.id;
        }
        const issuuPdfUrlSettions = projectSetting.find((item) => {
          return item.project_setting === "ISSUU_PDF_URL";
        });
        if (issuuPdfUrlSettions) {
          this.issuuPdfUrl = issuuPdfUrlSettions.value;
          this.idIssuuPdfUrl = issuuPdfUrlSettions.id;
        }
      }
    },
    async deleteProjectImage(projectImageId) {
      try {
        await api.deleteProjectImage(projectImageId);
        this.$vs.notify({
          time: 6000,
          title: `Imagen eliminada.`,
          text: `La imagen fue eliminada correctamente.`,
          color: "success",
        });
        const projectId = this.$store.state.project.currentProjectId;
        this.projectImageGallery = await api.getProjectImages(
          projectId,
          "FINISHES"
        );
      } catch (e) {
        this.$vs.notify({
          title: `Error`,
          text: `No ha sido posible eliminar imágen en este momento.`,
          color: "danger",
        });
      }
    },
    checkMediaUrl(url, option) {
      if (option === "virtualSlideUrl") {
        this.isVirtualSlideUrl = true;
      }

      if (option === "youTubeUrl") {
        this.isYouTubeUrl = true;
      }

      if (url.includes("https://youtu.be/")) {
        return url.replace(
          "https://youtu.be/",
          "https://www.youtube.com/embed/"
        );
      }

      if (url.includes("https://www.youtube.com/watch?v=")) {
        return url.replace(
          "https://www.youtube.com/watch?v=",
          "https://www.youtube.com/embed/"
        );
      }

      return url;
    },
    checkPdfUrl(url) {
      if (url.includes(".pdf") || url == "") {
        this.isPdfUrl = true;
      } else {
        this.isPdfUrl = false;
      }
      return url;
    },
    checkEmbedUrl(url) {
      if (url.includes("https://docs.google.com/presentation") || url == "") {
        this.isEmbedSlideCode = true;
      } else {
        this.isEmbedSlideCode = false;
      }
      return url;
    },
  },
  computed: {
    config() {
      const projectName = slugify(this.project.name);
      const developerName = slugify(this.project.property_developer.name);
      return {
        bucketName: "flattlo-app",
        dirName: `${developerName}/projects/${projectName}/gallery`,
        region: "us-east-1",
        accessKeyId: process.env.VUE_APP_AWS_ID,
        secretAccessKey: process.env.VUE_APP_AWS_SK,
        s3Url: process.env.VUE_APP_S3_URL,
      };
    },
    S3Client() {
      return new S3(this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.image-box {
  margin-left: 10px;
}
.resize-card {
  width: 99%;
}
.embed-element {
  height: 565px;
}
.custom-image-gallery {
  width: 100px;
  height: 90px;
  object-fit: none;
}
</style>
