<template>
  <div>
    <FinishesImageForm />
  </div>
</template>

<script>
import FinishesImageForm from "../components/FinishesImageForm";
import { api } from "../services";
export default {
  components: {
    FinishesImageForm
  },
  data() {
    return {
      project: null
    };
  },
  async mounted() {
    const { projectId } = this.$route.params;
    this.$store.dispatch("project/currentProject", { projectId });
    this.project = await api.get(projectId);
  }
};
</script>
